/* This is used as client component but directive is not used because of https://github.com/vercel/next.js/discussions/46795 */

import type { MeasurementUnit } from '@cvent/venue-search-shared/types';
import { useCallback } from 'react';
import MeasurementUnitItem from '@/components/legacy/header/settings/measurement-unit/MeasurementUnitItem';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER } from '@/utils/analyticsClientHelper';
import useMap from '@/hooks/useMap';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';

type measurementUnitProps = {
  unitOptions: {
    imperial: string;
    metric: string;
  };
  title?: string;
  measurementUnit: string;
  setMeasurementUnit: (unit: MeasurementUnit) => void;
};

export default function MeasurementUnit({
  unitOptions,
  title,
  measurementUnit,
  setMeasurementUnit
}: measurementUnitProps): JSX.Element {
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const triggerSimpleClickFact = useCallback(
    (clickEntity: string) => {
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');
      analytics.reportFact<SimpleClickFact>(simpleClickFact);
    },
    [mapView, analytics]
  );
  const updateMeasurementUnit = useCallback(
    (changedUnit: MeasurementUnit) => {
      if (changedUnit !== measurementUnit) {
        triggerSimpleClickFact(changedUnit);
        setMeasurementUnit(changedUnit);
      }
    },
    [measurementUnit, setMeasurementUnit, triggerSimpleClickFact]
  );

  return (
    <div className="mb-5 flex flex-col">
      <span className="text-b-md text-neutral-90">{title}:</span>
      <div className="shadow-sm mt-2 inline-flex w-full rounded-lg" role="group">
        <MeasurementUnitItem
          text={unitOptions.imperial}
          selectedUnitType={measurementUnit}
          updateMeasurementUnit={updateMeasurementUnit}
          unitType="IMPERIAL"
        />
        <MeasurementUnitItem
          text={unitOptions.metric}
          selectedUnitType={measurementUnit}
          updateMeasurementUnit={updateMeasurementUnit}
          unitType="METRIC"
        />
      </div>
    </div>
  );
}
