'use client';

import Image from 'next/image';
import logo from '@/assets/images/cvent-logo.png';
import NavLink from '@/components/legacy/header/NavLink';
import { Link } from '@/lib/router-events/patch-router/link';
import type { Dictionary } from '@/dictionaries';
import { usePathname, useSearchParams } from 'next/navigation';
import { DISCOVER_URI, getHeaderLinks, HeaderLinkParameter } from '@/utils/getHeaderLinks';
import SettingsContainer from '@/components/legacy/header/settings/SettingsContainer';
import ShoppingCart from '@/components/legacy/header/cart/ShoppingCart';
import MobileMenu from '@/components/legacy/header/MobileMenu';
import styles from '@/components/legacy/header/Header.styles';
import type { AllVenueTypes } from '@/utils/settingsHelper';
import { APP, PHOENIX } from '@/constants/routeConstants';
import { useMemo, useCallback } from 'react';
import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER } from '@/utils/analyticsClientHelper';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { queryStringConstants } from '@/constants/queryConstants';
import { isValidParam } from '@/utils/urlHelper';
import cventAnalytics from '@cvent/analytics';
import SimpleClick from '@/analytics/actions/SimpleClick';

type Props = {
  isLoggedIn: boolean;
  hideShoppingCart: boolean;
  dictionary: Partial<Dictionary>;
  plannerSettingOptions: PlannerSettingOptions;
  headerLinkParam: HeaderLinkParameter;
  plannerLogoUrl: string | undefined;
  isPhxEvtContainerExpOn: boolean;
};

export type NavLink = {
  title: string;
  address: string;
  isActive?: boolean;
  ariaLabel: string;
  clickEntity: string;
};

type PlannerSettingLabelProps = {
  plannerSettingLabel?: string;
  label?: string;
  value: AllVenueTypes;
};

/**
 * sevLabels - special event venues e.g: aquarium and zoo, bar club, etc.
 * defaultVenueLabels - default venues e.g: convention center, restaurant, etc.
 * defaultIncludeSearchLabel - translated string for including default venues in search
 * specialEventVenues - translated string for special event venues
 * selectedSEVs - translated string for number of selected special event venues
 * selectAll - translated string for Select all
 * deselectAll - translated string for Deselect all
 */
export type PlannerSettingOptions = {
  sevLabels: Array<PlannerSettingLabelProps>;
  defaultVenueLabels: Array<PlannerSettingLabelProps>;
  defaultIncludeSearchLabel: string;
  specialEventVenues: string;
  selectedSEVs: string;
  selectAll: string;
  deselectAll: string;
};

export default function Header({
  isLoggedIn,
  hideShoppingCart,
  dictionary,
  plannerSettingOptions,
  headerLinkParam,
  plannerLogoUrl,
  isPhxEvtContainerExpOn
}: Props): JSX.Element {
  const { locale, headerStrings } = headerLinkParam;
  const { BASE_PATH, CVENT_APP_DOMAIN } = headerLinkParam.domains;
  const searchParams = useSearchParams();
  const isPhoenix = searchParams?.get(APP) === PHOENIX;
  const eventId = searchParams?.get(queryStringConstants.EVENT_ID);
  const isEventContext = isPhxEvtContainerExpOn && isPhoenix && isValidParam(eventId);
  const creationSource = searchParams?.get(queryStringConstants.CREATION_SOURCE);
  const isCreationSourceParamValid = isEventContext && isValidParam(creationSource);
  const isDiscoverActive = usePathname().includes(DISCOVER_URI);

  const {
    homePage,
    discoverBase,
    plannerPromotionHub,
    guestPromotionHub,
    luxuryMagazine,
    newGuestDestinationGuide,
    signupLink,
    loginLink
  } = getHeaderLinks({
    ...headerLinkParam,
    isPhoenix,
    isEventContext,
    eventId,
    isCreationSourceParamValid,
    creationSource
  });

  const { mapView } = useMap();
  const analytics = useAnalytics();
  const handleClick = useCallback(
    async (e, clickEntity: string, address: string) => {
      e.preventDefault();

      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');

      // cvent/analytics
      cventAnalytics.track(new SimpleClick(simpleClickFact));

      await analytics.reportFactImmediately<SimpleClickFact>(simpleClickFact);
      window.open(address, '_self');
    },
    [mapView, analytics]
  );

  const navLinks: NavLink[] = [
    {
      title: homePage.title,
      clickEntity: 'venues_tab',
      address: homePage.address,
      isActive: !isDiscoverActive,
      ariaLabel: headerStrings._venues
    },
    {
      title: plannerPromotionHub.title,
      clickEntity: 'promotions_tab',
      address: isLoggedIn ? plannerPromotionHub.address : guestPromotionHub.address,
      ariaLabel: headerStrings._promotions
    },
    {
      title: newGuestDestinationGuide.title,
      clickEntity: 'destinations_tab',
      address: newGuestDestinationGuide.address,
      ariaLabel: headerStrings._destinations
    },
    {
      title: discoverBase.title,
      clickEntity: 'discover_tab',
      address: discoverBase.address,
      isActive: isDiscoverActive,
      ariaLabel: headerStrings._discover_brand_page
    },
    {
      title: luxuryMagazine.title,
      clickEntity: 'luxury_tab',
      address: luxuryMagazine.address,
      ariaLabel: headerStrings._luxury_tab
    }
  ];

  const loginLinks: NavLink[] = [
    {
      title: headerStrings._sign_up,
      clickEntity: 'signup',
      address: signupLink.address,
      ariaLabel: headerStrings._sign_up
    },
    {
      title: headerStrings._log_in,
      clickEntity: 'login',
      address: loginLink.address,
      ariaLabel: headerStrings._log_in
    }
  ];

  const ariaLabels = useMemo(
    () => ({
      newTag: headerStrings.new,
      menu: headerStrings._action_menu,
      luxury: headerStrings._luxury_tab,
      settings: headerStrings._settings
    }),
    [headerStrings]
  );

  const settingsLabels = useMemo(
    () => ({
      imperial: headerStrings._unit_option_imperial,
      metric: headerStrings._unit_option_metric,
      unitLabel: headerStrings._toggle_unit_label,
      languageLabel: headerStrings.language_label,
      cancel: headerStrings.cancel,
      apply: headerStrings._apply,
      close: headerStrings._close
    }),
    [headerStrings]
  );

  return (
    <div className="flex h-[3.75rem] w-full items-center border-b border-solid border-b-[#cad0d3] bg-[#e9edef] lg:justify-start">
      <MobileMenu
        navLinks={navLinks}
        loginLinks={loginLinks}
        ariaLabels={ariaLabels}
        isLoggedIn={isLoggedIn}
        locale={locale}
        basePath={BASE_PATH}
        settingsLabels={settingsLabels}
        plannerSettingOptions={plannerSettingOptions}
      />
      <div className="z-0 -ml-8 flex w-full justify-center xl:ml-0 xl:justify-start xl:pl-6">
        <div className="relative h-[40px] w-[120px]">
          <Link href={homePage.address}>
            <Image
              src={plannerLogoUrl || logo}
              alt={headerStrings._supplier_network}
              priority
              sizes="120px"
              fill
              style={{
                objectFit: 'contain'
              }}
            />
          </Link>
        </div>
      </div>
      <nav className="absolute ml-40 mr-1 hidden items-baseline gap-4 xl:flex">
        {navLinks.map(menuItem => {
          const { title, address, isActive, ariaLabel, clickEntity } = menuItem;
          return (
            <NavLink
              key={title}
              title={title}
              address={address}
              isActive={isActive}
              ariaLabel={ariaLabel}
              clickEntity={clickEntity}
            />
          );
        })}
        <span className={styles.newTag}>{headerStrings.new}</span>
      </nav>
      <div className={styles.rightContainer}>
        <span className={`${hideShoppingCart ? 'hidden' : styles.rightLinks}`}>
          <ShoppingCart
            isLoggedIn={isLoggedIn}
            strings={dictionary}
            locale={locale}
            cventAppDomain={CVENT_APP_DOMAIN}
            isPhxEvtContainerExpOn={isPhxEvtContainerExpOn}
          />
        </span>
        <span className={`${styles.rightLinks} hidden xl:block`}>
          <SettingsContainer
            title={headerStrings._settings}
            locale={locale}
            localeStrings={settingsLabels}
            isLoggedIn={isLoggedIn}
            basePath={BASE_PATH}
            plannerSettingOptions={plannerSettingOptions}
          />
        </span>
        {!isLoggedIn &&
          loginLinks.map(link => (
            <span className={`${styles.rightLinks} hidden pb-2 xl:block`} key={link.address}>
              <Link
                href={link.address}
                aria-label={link.ariaLabel}
                onClick={e => handleClick(e, link.clickEntity, link.address)}
              >
                {link.title}
              </Link>
            </span>
          ))}
      </div>
    </div>
  );
}
