import { Action, type ActionProps } from '@cvent/analytics/Action';
import { MapView } from '@/constants/mapConstants';

/**
 * Properties that will be tracked as part of this action.
 */
export interface SimpleClickProps extends ActionProps {
  /**
   * Action type
   */
  type: 'csn_search_simple_click';
  /**
   * Entity clicked
   */
  click_entity: string;
  /**
   * Click type
   */
  click_type: string;
  /**
   * Diamond level of the venue
   */
  diamond_level: string;
  /**
   * Page name
   */
  page: string;
  /**
   * Position of venue in search results
   */
  position: number | 'null';
  /**
   * Venue id
   */
  venue_id: string;
  /**
   * Whether the venue is fast responder or not
   */
  fast_responder: string | 'null';
  /**
   * Map view
   */
  map_view: MapView | 'null';
  /**
   * Search term
   */
  search_term: string;
  /**
   * Page url
   */
  page_url: string;
  /**
   * Microsite id
   */
  microsite_id: string | 'null';
  /**
   * Microsite page id
   */
  microsite_page_id: string | 'null';
  /**
   * Metadata
   */
  meta_data: string | 'null';
  /**
   * Fact version
   */
  ver: string;
  /**
   * Filters applied on search results page
   */
  active_filters: string;
}

/**
 * @description This action tracks clicks on certain entities like button, link, etc.
 * @trigger This action is triggered when a user clicks on an entity
 */
export class SimpleClick extends Action<SimpleClickProps> {
  id = 'simple.click';
}

export default SimpleClick;
