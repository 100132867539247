/* This is used as client component but directive is not used because of https://github.com/vercel/next.js/discussions/46795 */

import { useCallback } from 'react';
import type { MeasurementUnit } from '@cvent/venue-search-shared/types';

interface MeasurementUnitItemProps {
  text: string;
  selectedUnitType: string;
  updateMeasurementUnit: (unit: MeasurementUnit) => void;
  unitType: MeasurementUnit;
}
export default function MeasurementUnitItem({
  text,
  selectedUnitType,
  updateMeasurementUnit,
  unitType
}: MeasurementUnitItemProps): JSX.Element {
  const roundedStyle = unitType === 'METRIC' ? 'rounded-r-sm' : 'rounded-l-sm';

  const styleSelected = `px-4 py-1 w-full h-8 text-b-md text-background bg-brand-60 ${roundedStyle} border-solid border-1 border-brand-60`;
  const styleNotSelected = `px-4 py-1 w-full h-8 text-b-md text-brand-60 bg-background ${roundedStyle} border-solid border-1 border-brand-60`;

  const handleClick = useCallback(() => {
    updateMeasurementUnit(unitType);
  }, [unitType, updateMeasurementUnit]);

  return (
    <button
      type="button"
      className={selectedUnitType === unitType ? styleSelected : styleNotSelected}
      onClick={handleClick}
      aria-label={text}
      aria-pressed={selectedUnitType === unitType}
    >
      {text}
    </button>
  );
}
