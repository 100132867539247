'use client';

import { ReactElement, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import useShoppingCart from '@/hooks/useShoppingCart';
import type { Locale } from '@/i18n';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import Badge from '@/components/legacy/header/cart/Badge';
import VenueAddedText from '@/components/legacy/header/cart/VenueAddedText';
import VenueListText from '@/components/legacy/header/cart/VenueListText';
import useAnalytics from '@/hooks/useAnalytics';
import { FACT_VERSION_SHOPPING_CART, SHOPPING_CART } from '@/utils/analyticsClientHelper';
import useMap from '@/hooks/useMap';
import { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import { formatMessage } from '@/utils/translationHelper';
import cventAnalytics from '@cvent/analytics';
import SimpleClick from '@/analytics/actions/SimpleClick';

const CSSTransition = dynamic(() => import('react-transition-group/CSSTransition'));
const Popper = dynamic(() => import('@cvent/carina/components/Popper'), { ssr: false });

// auto close timer in ms for shopping cart flyout for guest user
const SHOPPING_CART_FLYOUT_TIMER_GUEST = 4500;
// auto close timer in ms for shopping cart flyout for planner user
const SHOPPING_CART_FLYOUT_TIMER_PLANNER = 2500;
// venue added text flip timer in ms
const SHOPPING_CART_FLIP_TIMER = 1800;

type ShoppingCartContainerProps = {
  children: ReactElement;
  strings: Record<string, string>;
  locale: Locale;
  isLoggedIn: boolean;
  testID?: string;
};

/**
 * Wrapper for shopping cart
 */
export default function ShoppingCartContainer({
  strings,
  locale,
  children,
  isLoggedIn,
  testID
}: ShoppingCartContainerProps): JSX.Element {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const cart = useShoppingCart();
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const { isOpen, setIsOpen, items, isIncreasing, setHighlightRecentVenue } = cart;
  const [flip, setFlip] = useState(false);
  const [keepFlyoutOpen, setKeepFlyoutOpen] = useState(false);
  const cartSize = Object.keys(items).length;
  const { isMobile, isTablet, isSmallDesktop } = useBreakpoints();
  const popperCloseTimer = useRef<NodeJS.Timeout>();
  const flipTimer = useRef<NodeJS.Timeout>();
  const cartRef = useRef<HTMLDivElement>(null);

  function keepShoppingCartFlyoutOpen() {
    setKeepFlyoutOpen(true);
    clearTimeout(popperCloseTimer.current);
  }

  function closeShoppingCartFlyout() {
    setKeepFlyoutOpen(false);
  }

  const triggerSimpleCartFact = useCallback(() => {
    const simpleClickFact: SimpleClickFact = getSimpleClickFact(
      'null',
      SHOPPING_CART,
      'null',
      'null',
      mapView,
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      SHOPPING_CART,
      FACT_VERSION_SHOPPING_CART
    );

    // cvent/analytics
    cventAnalytics.track(new SimpleClick(simpleClickFact));

    analytics.reportFact<SimpleClickFact>(simpleClickFact);
  }, [mapView, analytics]);

  const toggleIsOpen = useCallback(() => {
    if (!isOpen) {
      triggerSimpleCartFact();
    }
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen, triggerSimpleCartFact]);

  const shoppingCartAriaLabel = useMemo(() => {
    if (cartSize > 0) {
      return formatMessage(strings.CsnSearchApp_shopping_cart_button_with_badge, locale, {
        venueCount: cartSize
      });
    }
    return strings._venue_cart;
  }, [cartSize, locale, strings.CsnSearchApp_shopping_cart_button_with_badge, strings._venue_cart]);

  useEffect(() => {
    if (isIncreasing && cartSize > 0) {
      setFlip(true);
      if (!isLoggedIn) {
        setIsOpen(true);
      }
      clearTimeout(flipTimer.current);
      flipTimer.current = setTimeout(() => {
        setFlip(false);
      }, SHOPPING_CART_FLIP_TIMER);
    }
  }, [isIncreasing, cartSize, isLoggedIn, setIsOpen]);

  useEffect(() => {
    if (isOpen && !keepFlyoutOpen) {
      popperCloseTimer.current = setTimeout(
        () => {
          setIsOpen(false);
          setHighlightRecentVenue(false);
        },
        isLoggedIn ? SHOPPING_CART_FLYOUT_TIMER_PLANNER : SHOPPING_CART_FLYOUT_TIMER_GUEST
      );
    }
  }, [isOpen, setIsOpen, isLoggedIn, keepFlyoutOpen, setHighlightRecentVenue]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="pt-2"
      onKeyDown={event => {
        if (event.key === 'Escape') {
          setIsOpen(false);
        }
      }}
    >
      <button
        data-cvent-id="shopping-cart-button"
        aria-label={shoppingCartAriaLabel}
        onClick={toggleIsOpen}
        ref={triggerRef}
        type="button"
        className={`-top-[5px] inline-flex rounded-full border border-solid border-[#006AE1] text-b-md sm:rounded-xl sm:pb-px sm:pr-2.5 sm:pt-0.5 ${
          isOpen || cartSize > 0 ? 'bg-[#006AE1] text-background' : 'text-brand-70'
        } relative overflow-hidden`}
      >
        <CSSTransition
          nodeRef={cartRef}
          in={flip}
          timeout={200}
          classNames={{
            enter: 'translate-x-0 translate-y-[150%]',
            enterActive:
              'transform-none transition-transform duration-200 ease-in-out delay-0 opacity-100 transition-opacity duration-0 ease-in delay-0',
            exitActive: 'translate-x-0 -translate-y-[150%] transition-transform duration-200 ease-in-out'
          }}
        >
          <div ref={cartRef} className="hidden lg:inline">
            {flip ? (
              <VenueAddedText key={strings._venue_added} label={strings._venue_added} />
            ) : (
              <VenueListText
                key={strings._venue_cart}
                venueCartLabel={strings._venue_cart}
                cartCountLabel={strings.venue_cart_count}
                cartSize={cartSize}
                isOpen={isOpen}
                locale={locale}
              />
            )}
          </div>
        </CSSTransition>
        <div className="lg:hidden">
          <VenueListText
            key={strings._venue_cart}
            venueCartLabel={strings._venue_cart}
            cartCountLabel={strings.venue_cart_count}
            cartSize={cartSize}
            isOpen={isOpen}
            locale={locale}
          />
        </div>
      </button>
      <span className="absolute inline text-background sm:hidden lg:inline">
        {cartSize > 0 && (
          <Badge
            pulse={cartSize === 1 && isIncreasing ? 'pulseAndFade' : 'pulse'}
            isCartUpdated={isIncreasing}
            dataCventId={testID as string}
          >
            {cartSize}
          </Badge>
        )}
      </span>
      {isOpen && (
        <div
          onMouseEnter={keepShoppingCartFlyoutOpen}
          onMouseOver={keepShoppingCartFlyoutOpen}
          onFocus={keepShoppingCartFlyoutOpen}
          onTouchStart={keepShoppingCartFlyoutOpen}
          onTouchEnd={closeShoppingCartFlyout}
          onMouseLeave={closeShoppingCartFlyout}
        >
          <Popper
            triggerRef={triggerRef}
            isOpen={isOpen}
            arrowSize={!isMobile ? 'large' : undefined}
            placement={isLoggedIn || isMobile || isTablet || isSmallDesktop ? 'bottom-end' : undefined}
            preventOverflow={isMobile}
          >
            <div className="mt-2 w-[99vw] rounded-xl bg-background text-b-md text-[#1a2026] shadow-4 md:mt-0 md:w-[375px]">
              <div>{children}</div>
            </div>
          </Popper>
        </div>
      )}
    </div>
  );
}
