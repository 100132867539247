'use client';

import Icon from '@/components/shared/Icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { Link } from '@/lib/router-events/patch-router/link';
import { type Locale } from '@/i18n';
import type { NavLink, PlannerSettingOptions } from '@/components/legacy/header/Header';
import SettingsContainer from '@/components/legacy/header/settings/SettingsContainer';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import useAnalytics from '@/hooks/useAnalytics';
import useMap from '@/hooks/useMap';
import { HEADER } from '@/utils/analyticsClientHelper';
import SimpleClick from '@/analytics/actions/SimpleClick';
import cventAnalytics from '@cvent/analytics';

const Popper = dynamic(() => import('@/components/shared/popper/Popper'), { ssr: false });

type Props = {
  navLinks: NavLink[];
  loginLinks: NavLink[];
  ariaLabels: Record<string, string>;
  isLoggedIn: boolean;
  locale: Locale;
  basePath: string;
  settingsLabels: Record<string, string>;
  plannerSettingOptions: PlannerSettingOptions;
};

export default function MobileMenu({
  navLinks,
  loginLinks,
  ariaLabels,
  isLoggedIn,
  locale,
  basePath,
  settingsLabels,
  plannerSettingOptions
}: Props): JSX.Element {
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const handleClick = useCallback(
    async (e, clickEntity, address) => {
      e.preventDefault();
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');

      // cvent/analytics
      cventAnalytics.track(new SimpleClick(simpleClickFact));
      await analytics.reportFactImmediately<SimpleClickFact>(simpleClickFact);
      window.open(address, '_self');
    },
    [mapView, analytics]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [canCloseHeaderPopper, setCanCloseHeaderPopper] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const togglePopper = useCallback(() => {
    if (!canCloseHeaderPopper) {
      setIsOpen(!isOpen);
    }
  }, [isOpen, canCloseHeaderPopper]);

  const disableHeaderPopperDismiss = useCallback(() => {
    setCanCloseHeaderPopper(!canCloseHeaderPopper);
  }, [canCloseHeaderPopper]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    if (!canCloseHeaderPopper) {
      document.body.style.overflow = '';
    }
  }, [isOpen, canCloseHeaderPopper]);

  const { menu, luxury, newTag } = ariaLabels;
  return (
    <div className="z-30 xl:hidden">
      <button type="button" className="block p-2 py-3" aria-label={menu} onClick={togglePopper} ref={triggerRef}>
        {!isOpen ? (
          <Icon id="hamburger-menu" className="left-0 top-0 h-7 w-7" />
        ) : (
          <Icon id="x" className="left-0 top-0 h-7 w-7" />
        )}
      </button>
      <div className={`${isOpen ? 'absolute top-40 h-screen w-screen bg-neutral-90 opacity-80' : ''}`} />
      {isOpen && (
        <Popper isOpen={isOpen} onDismiss={togglePopper} triggerRef={triggerRef} placement="bottom-start">
          <div className="flex max-h-[calc(100vh_-_10rem)] w-screen flex-col overflow-y-auto bg-background shadow-2 xl:hidden">
            <div className="flex flex-col font-primary font-[300] text-neutral-60">
              {navLinks.map(menuItem => {
                const { title, address, ariaLabel, clickEntity } = menuItem;
                return (
                  <div
                    key={ariaLabel}
                    className="border-b border-b-neutral-30 px-4 pb-2 pt-2 first:border-t first:border-t-neutral-90 last:border-none"
                  >
                    <Link
                      className="px-1"
                      href={address}
                      aria-label={ariaLabel}
                      onClick={e => handleClick(e, clickEntity, address)}
                    >
                      {title}
                    </Link>
                    {title === luxury ? (
                      <sup>
                        <span className="relative-left-4 top-0 self-start rounded-xl bg-brand-60 px-1 py-1 text-b-xs text-fill">
                          {newTag}
                        </span>
                      </sup>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div
              className={`mt-7 flex flex-col ${
                !isLoggedIn ? 'border-b border-b-neutral-30' : ''
              } px-4 py-2 font-primary text-b-base font-[300] text-neutral-60`}
            >
              <SettingsContainer
                title={ariaLabels.settings}
                localeStrings={settingsLabels}
                isLoggedIn={isLoggedIn}
                locale={locale}
                basePath={basePath}
                plannerSettingOptions={plannerSettingOptions}
                disableHeaderPopperDismiss={disableHeaderPopperDismiss}
              />
            </div>

            {!isLoggedIn ? (
              <div className="flex flex-col font-primary text-b-base font-[300] text-neutral-60">
                {loginLinks.map(link => {
                  const { ariaLabel, address, title, clickEntity } = link;
                  return (
                    <Link
                      key={ariaLabel}
                      href={address}
                      aria-label={ariaLabel}
                      className="border-b border-b-neutral-30 px-4 py-2 last:border-none"
                      onClick={e => handleClick(e, clickEntity, address)}
                    >
                      {title}
                    </Link>
                  );
                })}
              </div>
            ) : null}
          </div>
        </Popper>
      )}
    </div>
  );
}
