'use client';

import Icon from '@/components/shared/Icons';

import Link from 'next/link';
import { APP, PHOENIX } from '@/constants/routeConstants';
import { getHeaderLinks } from '@/utils/getHeaderLinks';
import type { HeaderLinkParameter } from '@/utils/getHeaderLinks';
import { useSearchParams } from 'next/navigation';
import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER, convertToSnakeCase } from '@/utils/analyticsClientHelper';
import { useCallback } from 'react';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import { queryStringConstants } from '@/constants/queryConstants';
import { isValidParam } from '@/utils/urlHelper';

function getStyles(rfpName: string | null | undefined): string {
  return rfpName
    ? 'absolute ml-[23px] inline-block max-w-[19%] overflow-hidden text-ellipsis whitespace-nowrap text-brand-10 hover:text-brand-10 hover:no-underline focus:text-brand-10 focus:no-underline active:text-brand-10 active:no-underline md:ml-[5px] md:mt-0 md:min-w-0 md:max-w-[50%]'
    : 'absolute ml-[23px] inline-block overflow-hidden text-ellipsis whitespace-nowrap text-brand-10 hover:text-brand-10 hover:no-underline focus:text-brand-10 focus:no-underline active:text-brand-10 active:no-underline md:ml-[5px] md:mt-0';
}

type RibbonProps = {
  headerLinkParam: HeaderLinkParameter;
  isPhxEvtContainerExpOn: boolean;
};

// Component to render Logged-in Ribbon
export default function LoggedInRibbon({ headerLinkParam, isPhxEvtContainerExpOn }: RibbonProps): JSX.Element {
  const { rfpName } = headerLinkParam;
  const isPhoenix = useSearchParams()?.get(APP) === PHOENIX;
  const eventId = useSearchParams()?.get(queryStringConstants.EVENT_ID);
  const isEventContext = isPhxEvtContainerExpOn && isPhoenix && isValidParam(eventId);
  const creationSource = useSearchParams()?.get(queryStringConstants.CREATION_SOURCE);
  const isCreationSourceParamValid = isEventContext && isValidParam(creationSource);
  const { mapView } = useMap();
  const analytics = useAnalytics();

  const triggerSimpleClickFact = useCallback(
    (clickEntity: string) => {
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');
      analytics.reportFact<SimpleClickFact>(simpleClickFact);
    },
    [mapView, analytics]
  );
  const { rfpNameLink, rfpText, supplierAndResponseUrl, phoenixUrl, normandyLinkText, normandyLinkTextMobile } =
    getHeaderLinks({
      ...headerLinkParam,
      isPhoenix,
      isEventContext,
      eventId,
      isCreationSourceParamValid,
      creationSource
    });
  return (
    <div className="relative h-[1.25rem] w-full bg-[#0050ac] px-2 font-primary text-b-sm text-brand-10 md:pl-[30px] md:pr-0">
      <div className="">
        <Link
          aria-label={normandyLinkText}
          href={isPhoenix ? phoenixUrl : supplierAndResponseUrl}
          onClick={() => triggerSimpleClickFact('back_to_all_rfps_logged_in_header')}
          className="hover:text-brand-10 hover:no-underline focus:text-brand-10 focus:no-underline active:text-brand-10 active:no-underline"
        >
          <div className="absolute left-2 top-0 normal-case not-italic normal-nums leading-none antialiased">
            <Icon id="arrow-left" height={20} width={20} fill="background" />
          </div>
          <span className={`${getStyles(rfpName)} md:hidden`}>{normandyLinkTextMobile}</span>
          <span className={`${getStyles(rfpName)} hidden md:inline`}>{normandyLinkText}</span>
        </Link>
        {rfpName && (
          <Link
            aria-label={rfpName}
            href={rfpNameLink}
            className="absolute right-3 overflow-hidden text-ellipsis whitespace-nowrap"
            onClick={() => triggerSimpleClickFact(`${convertToSnakeCase(rfpName)}_logged_in_header`)}
          >
            {rfpText}
          </Link>
        )}
      </div>
    </div>
  );
}
