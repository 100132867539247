'use client';

import { useEffect } from 'react';

type Props = {
  labels: {
    message: string;
    dismiss: string;
  };
  locale: string;
  showConsentBanner: boolean;
};

const BRAND_60_COLOR_CODE = '#006ce8';

export default function ConsentCookieBanner({ labels, locale, showConsentBanner }: Props) {
  useEffect(() => {
    async function loadBanner() {
      const cookieConsent = (await import('cvent-cookieconsent')).default;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('cvent-cookieconsent/lib/cookieconsent.min.css');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('@/components/consent/consent.css');

      cookieConsent({
        language: locale,
        showLink: false,
        content: {
          message: labels.message,
          dismiss: labels.dismiss,
          close: '&times;'
        },
        palette: {
          popup: {
            background: '#E9EDEF', // Default value from dependency
            text: '#3b4d54', // Default value from dependency
            link: BRAND_60_COLOR_CODE
          },
          button: {
            background: BRAND_60_COLOR_CODE,
            text: '#f4f8f9' // Default value from dependency
          }
        },
        onPopupOpen: () => {
          const windowElement = document.getElementsByClassName('cc-window')[0];
          document.body.style.paddingBottom = `${windowElement.clientHeight}px`;
        },
        onStatusChange: (status: string) => {
          if (status === 'dismiss') {
            document.body.style.paddingBottom = '0px';
            window.location.reload();
          }
        }
      });
    }

    // Load the banner script
    const element = document.getElementsByClassName('cc-window');
    if (showConsentBanner && element.length === 0) {
      loadBanner();
    }

    // On locale change, update the content of the banner based on the selected locale
    const message = document.getElementsByClassName('cc-message');
    const dismiss = document.getElementsByClassName('cc-dismiss');
    if (message?.length > 0 && dismiss?.length > 0) {
      message[0].innerHTML = labels.message;
      dismiss[0].innerHTML = labels.dismiss;
      // Updated the body height based on the height of cookie banner
      document.body.style.paddingBottom = `${document.getElementsByClassName('cc-window')[0].clientHeight}px`;
    }
  }, [labels.dismiss, labels.message, locale, showConsentBanner]);

  return null;
}
