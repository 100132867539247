'use client';

import { ImageProps } from 'next/image';
import { useCallback } from 'react';
import { useSearchParams } from 'next/navigation';
import { VENUE_LIMIT_GUEST_USER, getRemainingVenueCountMessage } from '@/utils/shoppingCartHelper';
import useShoppingCart from '@/hooks/useShoppingCart';
import VenueLocation from '@/components/legacy/header/cart/VenueLocation';
import { formatMessage } from '@/utils/translationHelper';
import type { Locale } from '@/i18n';
import placeholder from '@/assets/images/shopping-cart-placeholder.png';
import { updateVenueUrl } from '@/utils/urlHelper';
import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import type { ShoppingCartFact } from '@/analytics/models/cventAnalytics/ShoppingCartFact';
import { queryStringConstants } from '@/constants/queryConstants';
import type { ShoppingCartItem } from '@/models/ShoppingCartItem';
import type { VenueProfileFactParams } from '@/components/card/VenueCard';
import { SHOPPING_CART } from '@/utils/analyticsClientHelper';
import { getShoppingCartFact } from '@/analytics/helpers/cventAnalytics/getShoppingCartFact';
import ImageComponent from '@/components/ImageComponent';
import useSpinner from '@/hooks/useSpinner';
import CartUpdated from '@/analytics/actions/CartUpdated';
import cventAnalytics from '@cvent/analytics';

type RequestedVenueListProps = {
  isLoggedIn: boolean;
  strings: Record<string, string>;
  dataCventId: string;
  locale: Locale;
  hubCategory: string | undefined;
};

export default function RequestedVenueList({
  isLoggedIn,
  strings,
  dataCventId,
  locale,
  hubCategory
}: RequestedVenueListProps): JSX.Element {
  const cart = useShoppingCart();
  const cartSize = Object.keys(cart.items).length;
  const searchParams = useSearchParams();
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const { setShowSpinner } = useSpinner();
  const rfpId = searchParams?.get('rfpId') ?? 'EXTERNAL_SHOPPING_CART';
  const remainingVenueCountMessage = getRemainingVenueCountMessage(isLoggedIn, cartSize, strings, locale);
  const triggerShoppingCartFact = useCallback(
    (item: ShoppingCartItem) => {
      const shoppingCartFact: ShoppingCartFact = getShoppingCartFact(
        'delete',
        item,
        cart,
        searchParams?.get(queryStringConstants.PAGE_NUMBER),
        searchParams?.get(queryStringConstants.SORT),
        mapView,
        'null',
        SHOPPING_CART
      );
      analytics.reportFact<ShoppingCartFact>(shoppingCartFact);

      // cvent/analytics
      cventAnalytics.track(new CartUpdated(shoppingCartFact));
    },
    [cart, searchParams, mapView, analytics]
  );

  const handleClick = useCallback(
    (e, venue) => {
      e.preventDefault();
      const venueProfileFactParams: VenueProfileFactParams = {
        adId: venue.adId !== 'null' && venue.adId !== null ? venue.adId : undefined,
        adType: venue.featuredLevel,
        adPos: venue.adPosition !== 'null' ? venue.adPosition : undefined,
        searchResultsRank: venue.searchResultsRank,
        searchTerm: venue.searchTerm,
        source: venue.source
      };
      const venueUrl = updateVenueUrl(venue.url, searchParams, venueProfileFactParams, hubCategory);
      // handles command + click event
      if (e.metaKey) {
        window.open(venueUrl);
      } else {
        setShowSpinner(true);
        window.open(venueUrl, '_self');
      }
    },
    [hubCategory, searchParams, setShowSpinner]
  );
  const removeVenue = useCallback(
    (e, venue) => {
      e.preventDefault();
      e.stopPropagation();
      triggerShoppingCartFact(venue);
      cart.updateCart({ type: 'delete', item: venue, rfpId });
    },
    [cart, rfpId, triggerShoppingCartFact]
  );
  const cartVenues = Object.keys(cart.items)
    .map(key => cart.items[key])
    .reverse();
  let venueOutput;
  const { highlightRecentVenue } = cart;
  const venueList = cartVenues.map((venue, index) => {
    const venueType = venue.type ? strings[`_venue_type_${venue.type.toLowerCase()}`] : null;
    const venuePromotionSection = venue.promotionTitle && (
      <span data-cvent-id={`shopping-cart-promotion-${venue.promotionId}`} className="font-[700]">
        {strings._promotion} {venue.promotionTitle}
      </span>
    );
    const location = (
      <VenueLocation
        city={venue.city}
        stateCode={venue.stateProvinceCode ?? ''}
        countryCode={venue.countryCode}
        dataCventId={`${dataCventId}-venue-location`}
      />
    );
    const removeAction = (
      <button
        type="button"
        onClick={e => removeVenue(e, venue)}
        className="overflow-hidden text-ellipsis border-none bg-none p-0 text-left font-[400] text-[#006AE1] hover:text-[#004CD6]"
        data-cvent-id={`${dataCventId}-remove-button`}
      >
        {strings._remove}
      </button>
    );

    const imageProps: ImageProps = {
      fill: true,
      style: { objectFit: 'cover', width: '100%', borderRadius: '10px' },
      src: venue.ofrgImage ?? placeholder.src,
      alt: strings.csn_search_image_fallbackText
    };

    // TODO: Alt Text For Images
    if (highlightRecentVenue && index === 0) {
      venueOutput = (
        <div className="aspect-[2] w-full">
          <div className="relative mb-[15px] h-full w-full" data-cvent-id={`${dataCventId}-venue-wrapper`}>
            <div>
              <ImageComponent imageProps={imageProps} />
            </div>

            <div
              className="absolute bottom-0 left-0 right-0 top-0 rounded-bl-xl rounded-br-xl bg-[linear-gradient(180deg,rgba(34,40,42,0),rgba(34,40,42,0)_50%,#22282a)]"
              data-cvent-id={`${dataCventId}-overlay`}
            />
            <div
              className="absolute bottom-0 left-0 w-full p-[10px] text-[14px] leading-[1.3] text-[#fff]"
              data-cvent-id={`${dataCventId}-venue-info-wrapper`}
            >
              <h5
                data-cvent-id={`${dataCventId}-overlay-venue-${venue.venueId}-name`}
                className="whitespace-no-wrap mb-[5px] overflow-hidden text-ellipsis"
              >
                {venue.name}
              </h5>
              <div className="font-[300]" data-cvent-id={`${dataCventId}-venue-type`}>
                {location}
                {venueType && ` ${venueType}`}
              </div>
              {venuePromotionSection}
            </div>
          </div>
          <div className="relative">
            <div className="absolute right-0 top-[-12px]" data-cvent-id={`${dataCventId}-remove-action`}>
              {removeAction}
            </div>
            {remainingVenueCountMessage && <p className="pt-[4px]">{remainingVenueCountMessage}</p>}
          </div>
        </div>
      );
    } else {
      venueOutput = (
        <div className="grid grid-cols-5">
          <div className="col-span-2 aspect-[1.6] w-full">
            <div className="relative h-full w-full">
              <ImageComponent imageProps={{ ...imageProps, sizes: '(max-width: 640px) 100vw, 25vw' }} />
            </div>
          </div>
          <div className="col-span-3 ml-[10px] leading-[1.2]">
            <h5
              className="mb-[5px] line-clamp-2 overflow-hidden text-ellipsis text-[12px] font-[400]"
              title={venue.name}
              data-cvent-id={`${dataCventId}-venue-${venue.venueId}-name`}
            >
              {venue.name}
            </h5>
            <div className="mb-[10px] text-[12px] font-[300]">
              <div data-cvent-id={`${dataCventId}-venue-location`}>{location}</div>
              {venueType && <div data-cvent-id={`${dataCventId}-venue-location-type`}>{venueType}</div>}
              {venuePromotionSection}
            </div>
            {removeAction}
          </div>
        </div>
      );
    }
    return (
      <li
        className="border-t border-solid border-t-[#D9DCDE] first:border-t-0 hover:border-t-[#eeeef0]"
        key={venue.venueId}
      >
        {/* The purpose of using "href" is for SEO, while "onClick" is used to construct the URL on the client side with parameters and then open the profile page. */}
        <a
          href={venue.url}
          data-cvent-id={`${dataCventId}-${venue.venueId}-url`}
          onClick={e => handleClick(e, venue)}
          className="block py-2.5 pl-2 pr-5 text-[#1A2026] hover:bg-[#eeeff0] hover:text-[#1A2026] hover:no-underline focus:bg-[#eeeff0] focus:text-[#1A2026]"
        >
          <div className="clip-1 absolute h-1 w-1 overflow-hidden">
            {formatMessage(strings.CsnSearchApp_shopping_cart_venue_link, locale, {
              venueName: venue.name
            })}
          </div>
          {venueOutput}
        </a>
      </li>
    );
  });

  let emptyCartText = formatMessage(strings.no_cart_venues_limit, locale, {
    number: VENUE_LIMIT_GUEST_USER
  });

  if (isLoggedIn) {
    emptyCartText = strings._no_cart_venues_logged_in;
  }

  const noVenueMessage = cartVenues.length === 0 && (
    <div className="min-h-[100px] p-2.5 text-[#1A2026]">{emptyCartText}</div>
  );

  return (
    <div className="ml-auto mr-auto px-2.5">
      <ul className="-mx-5 -my-2.5 list-none p-2.5">
        {venueList}
        {noVenueMessage}
      </ul>
    </div>
  );
}
