import { Action, type ActionProps } from '@cvent/analytics/Action';
import { MapView } from '@/constants/mapConstants';

/**
 * Properties that will be tracked as part of this action.
 */
export interface CartUpdatedProps extends ActionProps {
  /**
   * Fact type
   */
  type: 'csn_search_add_to_cart' | 'csn_search_remove_from_cart';
  /**
   * Filters applied on the search results page
   */
  active_filters: string;
  /**
   * Ad id of the venue (if any)
   */
  ad_id: string;
  /**
   * Ad position of the venue (if any)
   */
  ad_position: number | 'null';
  /**
   * Ad type of the venue (if any)
   */
  ad_type: string;
  /**
   * Auto added flag
   */
  auto_added: string;
  /**
   * Whether the venue is fast responder or not
   */
  fast_responder: string | 'null';
  /**
   * Metadata
   */
  meta_data: string;
  /**
   * Microsite data
   */
  microsite_data: string;
  /**
   * Page number
   */
  current_page_number: number;
  /**
   * Page name
   */
  page: string;
  /**
   * Promotion id (if any)
   */
  promotion_id: string;
  /**
   * Promotion type (if any)
   */
  promotion_type: string;
  /**
   * Rank of the venue in search results
   */
  search_result_rank: number | 'null';
  /**
   * Rank of the venue in shopping cart
   */
  shopping_cart_rank: number;
  /**
   * Selected sort type on search results page
   */
  sort_type: string;
  /**
   * Venue name
   */
  venue: string;
  /**
   * Fact version
   */
  ver: string;
  /**
   * Map view
   */
  map_view: MapView | 'null';
  /**
   * Search term
   */
  search_term: string;
  /**
   * Page url
   */
  page_url: string;
  /**
   * Source
   */
  source: string;
}

/**
 * @description This action tracks the actions of adding or removing venues from the shopping cart
 * @trigger This action is triggered when a user adds or remove venue from the shopping cart
 */
export class CartUpdated extends Action<CartUpdatedProps> {
  id = 'cart.updated';
}

export default CartUpdated;
