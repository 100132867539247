import { Action, type ActionProps } from '@cvent/analytics/Action';
import { MapView } from '@/constants/mapConstants';

/**
 * Properties that will be tracked as part of this action.
 */
export interface RfpRequestedProps extends ActionProps {
  /**
   * Fact type
   */
  type: 'csn_submit_request';
  /**
   * Page target type
   */
  page_target_type: string;
  /**
   * Venues in cart
   */
  venues: Array<string>;
  /**
   * Ads in cart
   */
  ad_ids: Array<string>;
  /**
   * Map view
   */
  map_view: MapView;
  /**
   * Page url
   */
  page_url: string;
  /**
   * Count of venues in cart
   */
  venue_count: number;
  /**
   * Referrer (if any)
   */
  referrer: string;
  /**
   * RFP id (if in RFP context)
   */
  rfp_id: string;
}

/**
 * @description This action tracks the submission of a rfp from the shopping cart
 * @trigger This action is triggered when a user submits a rfp from shopping cart
 */
export class RfpRequested extends Action<RfpRequestedProps> {
  id = 'rfp.requested';
}

export default RfpRequested;
